"use client";

import { cn } from "@/lib/utils";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { SidebarTrigger } from "./ui/sidebar";
import KatanaDouble from "./images/KatanaDouble";
import { CgSidebarOpen } from "react-icons/cg";
import { GoSidebarExpand } from "react-icons/go";
import { MdOutlineTouchApp } from "react-icons/md";

export interface HeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  className?: string;
}

export const Header = ({ children, className, ...rest }: HeaderProps) => {
  const headerRef = useRef<HTMLDivElement | null>(null);
  const [headerHeight, setHeaderHeight] = useState<number>(80);

  // useEffect(() => {
  //   const updateHeaderHeight = () => {
  //     if (headerRef.current) {
  //       setHeaderHeight(headerRef.current.offsetHeight);
  //     }
  //   };

  //   // Initial height update
  //   updateHeaderHeight();

  //   // Update height on window resize
  //   window.addEventListener("resize", updateHeaderHeight);

  //   return () => {
  //     window.removeEventListener("resize", updateHeaderHeight);
  //   };
  // }, []);

  return (
    <>
      <header
        ref={headerRef}
        className={cn(
          "fixed top-0 z-[50] w-webkit-fill w-moz-available flex items-center  justify-center  shadow-md md:shadow-xl honk-gradient-4 border-b-2 border-honk-4 transition-all group",
          className
        )}
        style={{ height: `${headerHeight}px` }}
        {...rest}
      >
        <em className="font-honk not-italic h-fit flex flex-row items-center gap-4 p-2 w-full justify-end">
          <span className="text-right text-xl md:text-2xl whitespace-nowrap">
            SMART SAMURAI
            <br />
            KRZYSZTOF KUTNIOWSKI
          </span>
          <KatanaDouble className=" size-14" />
        </em>
        {/* <SidebarTrigger className="absolute size-full opacity-0" /> */}
      </header>
    </>
  );
};

// commit3
