import * as React from "react";

const MOBILE_BREAKPOINT = 640;

interface Props {
  breakpoint?: number;
}

export function useIsMobile(props?: Props) {
  const finalBreakpoint = props?.breakpoint ?? MOBILE_BREAKPOINT;

  const [isMobile, setIsMobile] = React.useState<boolean | undefined>(
    undefined
  );

  React.useEffect(() => {
    const mql = window.matchMedia(`(max-width: ${finalBreakpoint - 1}px)`);
    const onChange = () => {
      setIsMobile(window.innerWidth < finalBreakpoint);
    };
    mql.addEventListener("change", onChange);
    setIsMobile(window.innerWidth < finalBreakpoint);
    return () => mql.removeEventListener("change", onChange);
  }, [finalBreakpoint]);

  return !!isMobile;
}
