"use client";

import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarProvider,
  SidebarTrigger,
} from "@/components/ui/sidebar";
import { cn } from "@/lib/utils";
import Link from "next/link";
import { useState } from "react";
import { CgSidebarOpen } from "react-icons/cg";
import { IoIosCloseCircle } from "react-icons/io";
import { MdOutlineTouchApp } from "react-icons/md";

const StronaGlownaItems = [
  {
    href: "/",
    title: "O firmie",
  },
  {
    href: "/kontakt",
    title: "Kontakt",
  },
];

const OfertaItems = [
  {
    href: "/oferta/smart-home",
    title: "Smart Home",
  },
  {
    href: "/oferta/web-development",
    title: "Web Development",
  },
  {
    href: "/oferta/serwery-nextcloud",
    title: "Serwery Nextcloud",
  },
  {
    href: "/oferta/sprzet",
    title: "Komputery osobiste",
  },
];

export function AppSidebar() {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <SidebarProvider
      open={open}
      onOpenChange={() => setOpen((prev) => !prev)}
      className={cn("relative", open ? "" : "size-0")}
      defaultOpen={false}
      style={{
        // @ts-ignore
        "--sidebar-width": "20rem",
        "--sidebar-width-mobile": "20rem",
      }}
    >
      <div className="fixed left-0 z-[50] top-4  flex items-center gap-2 flex-row text-black group">
        <MdOutlineTouchApp className="ml-4 transition-all  group-hover:animate-ping   size-10" />
        <SidebarTrigger className="size-full absolute top-0 left-0 opacity-0" />
        <div className="bg-black/75 group-hover:bg-black p-2 rounded transition-all  md:block hidden">
          <p className="text-bold text-honk-gradient ">Nawigacja</p>
        </div>
      </div>
      <SidebarTrigger />
      <Sidebar className="border-honk-4 z-[100]" variant="floating" side="left">
        <SidebarHeader className="text-xl flex flex-row justify-between items-center  uppercase font-bold  !bg-black border-b-2 border-honk-4">
          <h2 className="text-honk-gradient bg-transparent w-full">MENU</h2>
          <SidebarTrigger className="hover:bg-transparent size-8">
            <IoIosCloseCircle className="rotate-180 transition-all stroke-black text-honk-3  stroke-[1] size-10" />
          </SidebarTrigger>
        </SidebarHeader>
        <SidebarContent className="text-white bg-black">
          <SidebarGroup className=" flex flex-col gap-2 ">
            <SidebarGroupLabel className="py-2 px-0 ">
              <h2 className="w-full h-fit px-2 bg-honk-4 text-white">
                Strona główna
              </h2>
            </SidebarGroupLabel>
            <SidebarGroupContent>
              <SidebarMenu>
                {StronaGlownaItems.map((item) => (
                  <SidebarMenuItem key={item.title}>
                    <SidebarMenuButton asChild>
                      <Link href={item.href}>
                        <>
                          <SidebarTrigger className="size-full absolute top-0 left-0 opacity-0" />
                          <h3>{item.title}</h3>
                        </>
                      </Link>
                    </SidebarMenuButton>
                  </SidebarMenuItem>
                ))}
              </SidebarMenu>
            </SidebarGroupContent>
          </SidebarGroup>
          <SidebarGroup className=" flex flex-col gap-2 ">
            <SidebarGroupLabel className="py-2 px-0 ">
              <h2 className="w-full h-fit px-2 bg-honk-4 text-white">Oferta</h2>
            </SidebarGroupLabel>
            <SidebarGroupContent>
              <SidebarMenu>
                {OfertaItems.map((item) => (
                  <SidebarMenuItem key={item.title}>
                    <SidebarMenuButton asChild>
                      <Link href={item.href}>
                        <>
                          <SidebarTrigger className="size-full absolute top-0 left-0 opacity-0" />
                          <h3>{item.title}</h3>
                        </>
                      </Link>
                    </SidebarMenuButton>
                  </SidebarMenuItem>
                ))}
              </SidebarMenu>
            </SidebarGroupContent>
          </SidebarGroup>
        </SidebarContent>

        <SidebarFooter className="bg-honk-4" />
      </Sidebar>
      {open && (
        <SidebarTrigger className="size-full absolute top-0 left-0 opacity-0 !cursor-default" />
      )}
    </SidebarProvider>
  );
}
